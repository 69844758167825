






































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import Base64FileInput from "@/components/Base64FileInput.vue";
import TagColorInput from "@/components/TagColorInput.vue";
import TagColorDisplay from "@/components/TagColorDisplay.vue";

export default Vue.extend({
  components: { Base64FileInput, TagColorInput, TagColorDisplay },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      form: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: this.$t("NAME"), value: "name" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: "Mã lô", value: "package_batch_id" },
        { text: "Giá mua", value: "product_purchase_price" },
        { text: "Số lượng", value: "product_count" },
        { text: "Đơn vị", value: "product_unit" },
        { text: "Thông tin SP", value: "product_description" },
        { text: "Giá so sánh", value: "product_compared_price" },
        { text: "Mong muốn khách hàng", value: "product_customer_need" },
        { text: "Tên tiếng anh", value: "product_english_name" },
        { text: "Mô tả", value: "product_note" },
        { text: "Giá XNK", value: "product_xnk_price" },
        { text: "Quy cách đóng gói", value: "product_package_method" },
        { text: "Trạng thái", value: "product_status" },
        { text: "Màu tag", value: "tag_color" },
        { text: this.$t("IMAGE"), value: "images" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      search: "",
      searchTagColor: null,
      package_batch_id: "",
      filterStatus: "all",
      filterProductNote: "",
      loading: false,
      data: [],
      dataCount: 0,
      selectedItems: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      productCustomerNeedMap: {
        kd: "Khai đúng",
        kt: "Khai thấp",
        klhd: "Không lấy hóa đơn",
      },
      availableStatuses: [
        {
          value: "all",
          text: "Tất cả đơn",
        },
        {
          value: "d",
          text: "Đủ",
        },
        {
          value: "cxn",
          text: "Chờ xác nhận",
        },
        {
          value: "t",
          text: "Trống",
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("CREATE_PACKAGE") : this.$t("EDIT");
    },
    productCustomerNeeds() {
      const items = [];
      for (const k in this.productCustomerNeedMap) {
        items.push({
          text: this.productCustomerNeedMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      const filters: any = [
        {
          key: "package_id",
          operator: "match",
          value: this.search,
        },
        {
          key: "package_batch_id",
          operator: "match",
          value: this.package_batch_id,
        },
        {
          key: "tag_color",
          operator: "equal_to",
          value: this.searchTagColor,
        },
      ];
      switch (this.filterStatus) {
        case "d":
          filters.push({
            key: "product_status",
            operator: "=",
            value: "Approved",
          });
          break;
        case "cxn":
          filters.push({
            key: "product_status",
            operator: "!=",
            value: "Approved",
          });
          filters.push({
            key: "product_customer_need",
            operator: "!=",
            value: "",
          });
          break;
        case "t":
          filters.push({
            key: "product_status",
            operator: "!=",
            value: "Approved",
          });
          filters.push({
            key: "product_customer_need",
            operator: "=",
            value: "",
          });
          break;
      }
      if (this.filterProductNote) {
        filters.push({
          key: "product_note",
          operator: "match",
          value: this.filterProductNote,
        });
      }
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters,
        extra: {
          register: true,
        },
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let fields = undefined;
      if (this.userManager.checkRole(["kd", "htkd"], true)) {
        fields = [
          "product_purchase_price",
          "product_count",
          "product_unit",
          "product_description",
          "product_compared_price",
          "product_customer_need",
          "product_package_method",
          "product_stampt",
          "product_status",
        ];
        this.editedItem.product_status = "Pending";
      } else if (this.userManager.checkRole(["xnk", "cs"], true)) {
        fields = ["product_unit", "product_english_name", "product_xnk_price", "product_note", "product_count"];
      }
      const result = await apiClient.packageUpdate(this.editedItem, fields);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async approveItem(item) {
      await apiClient.packageUpdate({
        id: item.id,
        product_status: "Approved",
      });
      await this.initialize();
    },
    async cancelApproveItem(item) {
      await apiClient.packageUpdate({
        id: item.id,
        product_status: "Pending",
      });
      await this.initialize();
    },
    exportItems() {
      location.href = apiClient.packageExport(
        null,
        this.selectedItems.map((item) => item.id)
      );
    },
  },
});
